<template>
  <ion-page class="page bg-transparent mt-4 scrollable">
    <div v-if="isAuthenticated" class="mt-3 mb-2 d-flex justify-content-between">
      <!-- <router-link :to="{ name: 'raffles' }" class="clickable-item-hov d-flex align-items-center text-black"
          ><i class="ti-angle-left mr-1" />Back to all raffles</router-link
        > -->
      <div>
        <router-link v-if="!isThankYouWall" :to="{ name: 'raffles' }">
          <ion-button class="back-btn">
            <div class="d-flex">
              <ion-icon class="icon mr-2" :icon="arrowBackOutline" />
              <span>Back to all raffles</span>
            </div>
          </ion-button>
        </router-link>
        <ion-button v-else class="back-btn" @click.prevent="() => (isThankYouWall = false)">
          <div class="d-flex">
            <ion-icon class="icon mr-2" :icon="arrowBackOutline" />
            <span>Go back</span>
          </div>
        </ion-button>
      </div>
      <div></div>

      <div v-if="user.id !== get(raffle, 'user.id')">
        <ion-button class="header-popover-button d-block" @click.stop="dropdownToggle"
          ><i class="ti-more-alt"
        /></ion-button>
      </div>
    </div>

    <div class="swiper-container" v-if="raffle?.visuals?.length">
      <ClientOnly>
        <swiper
          :modules="[SwiperAutoplay, SwiperNavigation]"
          navigation
          loop
          :pagination="{ clickable: true }"
          :autoplay="{ delay: 10000 }"
          class="swiper-base"
        >
          <swiper-slide v-for="(visual, index) in raffle?.visuals" :key="index" @click.stop="openVisualPreview(index)">
            <div class="position-relative slide clickable-item">
              <div :style="`background-image: url(${visual})`" class="slide slide-bg position-absolute"></div>
              <img loading="lazy" v-image :src="visual" class="slide slide-img position-relative" />
            </div>
          </swiper-slide>
        </swiper>
      </ClientOnly>
    </div>

    <div class="wrapper py-3">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="title">{{ raffle?.title }}</div>

        <div v-if="raffle?.is_nsfw" class="ml-2 d-flex align-items-center">
          <ion-badge color="danger">NSFW</ion-badge>
        </div>

        <div
          v-if="isRaffleOwner && raffle?.status != 'ended'"
          class="text-primary mx-2 clickable-item-hov"
          style="margin-top: 7px"
          @click="goToEdit"
        >
          Edit
        </div>
      </div>
      <div v-if="!isThankYouWall">
        <div class="d-flex justify-content-center text-center align-items-center flex-column py-3">
          <div v-if="raffle?.status === 'ended'" class="d-flex justify-content-center">
            <ion-badge color="danger" class="mr-1 badge">Ended</ion-badge>
          </div>
          <div v-if="raffle?.status === 'ongoing'" class="px-2 py-2 d-flex raf-border">
            <ion-icon class="icon" :icon="timeOutline" style="font-size: 45px" />
            <div class="flex-grow-1 mx-2">
              <div style="font-weight: bold; font-size: 24px; gap: 8px">
                <ClientOnly>
                  <vue-countdown
                    v-if="raffle && raffle?.start_date"
                    v-slot="{ days, hours, minutes, seconds }"
                    :time="countdownTime"
                    class="d-flex"
                    style="gap: 9px"
                  >
                    <div>{{ leadingZero(days) }}</div>
                    <div>:</div>
                    <div>{{ leadingZero(hours) }}</div>
                    <div>:</div>
                    <div>{{ leadingZero(minutes) }}</div>
                    <div>:</div>
                    <div>{{ leadingZero(seconds) }}</div>
                  </vue-countdown>
                </ClientOnly>
              </div>

              <div style="font-size: 13px; gap: 16px" class="d-flex">
                <span>DAYS</span>
                <span>HOURS</span>
                <span>MIN</span>
                <span class="ml-2">SEC</span>
              </div>
            </div>
          </div>
          <div v-if="isRaffleOwner" class="mt-3">
            <div class="d-flex">
              <span
                ><strong
                  >{{ entriesCount.total_entries_count }} Entr{{
                    (get(entriesCount, 'total_entries_count') || 0) > 1 ? 'ies' : 'y'
                  }}</strong
                ></span
              >
              <template v-if="thankyouCounts.total_thanks_count">
                <div class="mx-2">|</div>
                <span class="text-primary clickable-item-hov" @click="openThankyouWall"
                  >{{ thankyouCounts.total_thanks_count }} Thank you's</span
                >
              </template>
            </div>
          </div>
          <div v-else-if="raffle?.status === 'ongoing'" class="mt-3">
            <router-link v-if="!isAuthenticated" :to="{ name: 'login' }">
              <ion-button class="enter-btn"> Log in to enter! </ion-button>
            </router-link>

            <ion-button
              v-else-if="get(user, 'is_email_verified', true)"
              class="enter-btn"
              :disabled="isEntryLoading"
              @click="openRaffEntryModal"
            >
              {{ get(entryStatus, 'has_entered') ? `You've Entered!` : 'Enter raffle' }}
            </ion-button>
            <VerificationButton actionName="Enter raffle" v-else />
          </div>
        </div>

        <div class="d-flex py-3">
          <div class="sub-title d-flex">
            <i class="ti-crown" style="font-size: 23px" />
            <span class="mx-2"> Number of Winners:</span>
          </div>
          <div style="margin-top: 2px; font-size: 18px; margin-left: 2px">{{ raffle?.total_winners }}</div>
        </div>

        <div
          v-if="get(raffle, 'status') === 'ended' && (!isEmpty(runnerUps) || !isEmpty(winners))"
          class="raffle-card px-3 py-3 mb-3"
          style="border-radius: 10px"
        >
          <div class="sub-title text-primary">Results:</div>
          <div v-if="!isEmpty(winners)" class="d-flex px-2 py-2 flex-wrap">
            <div class="sub-co-title d-flex">
              <ion-icon class="icon mr-2" :icon="trophyOutline" />
              Winners:
            </div>
            <div v-for="(win, index) in winners" :key="index" class="mx-1">
              <router-link
                :class="{
                  'text-color-username':
                    usernameColor(get(win, 'user')) && !usernameColor(get(win, 'user')).startsWith('#'),
                  'bold-username': usernameColor(get(win, 'user')),
                }"
                :style="
                  !usernameColor(get(win, 'user'))
                    ? ''
                    : usernameColor(get(win, 'user')).startsWith('#')
                    ? `color: ${usernameColor(get(win, 'user'))} !important`
                    : `background-image: ${usernameColor(get(win, 'user'))} !important`
                "
                target="_blank"
                :to="{ name: 'profile', params: { username: get(win, 'user.username') } }"
              >
                @{{ get(win, 'user.username') }}
              </router-link>
            </div>
          </div>

          <div v-if="!isEmpty(runnerUps)" class="d-flex px-2 flex-wrap">
            <div class="sub-co-title d-flex">
              <ion-icon class="icon mr-2" :icon="ribbonOutline" />
              Runner Ups:
            </div>

            <div v-for="(runups, index) in runnerUps" :key="index" class="mx-1">
              <router-link
                :class="{
                  'text-color-username':
                    usernameColor(get(runups, 'user')) && !usernameColor(get(runups, 'user')).startsWith('#'),
                  'bold-username': usernameColor(get(runups, 'user')),
                }"
                :style="
                  !usernameColor(get(runups, 'user'))
                    ? ''
                    : usernameColor(get(runups, 'user')).startsWith('#')
                    ? `color: ${usernameColor(get(runups, 'user'))} !important`
                    : `background-image: ${usernameColor(get(runups, 'user'))} !important`
                "
                target="_blank"
                :to="{ name: 'profile', params: { username: get(runups, 'user.username') } }"
              >
                @{{ get(runups, 'user.username') }}
              </router-link>
            </div>
          </div>
        </div>

        <div class="raffle-card px-3 py-3 mb-3">
          <div class="sub-title text-primary">About this Raffle:</div>
          <div class="px-3 py-2">
            <span v-html="sanitizedDesc"></span>
          </div>
        </div>

        <div
          v-if="get(raffle, 'bonus_gateways.fanwork') || get(raffle, 'bonus_gateways.social_media_sharing')"
          class="raffle-card px-3 py-3 mb-3"
        >
          <div class="sub-title text-primary">Bonus Entries:</div>
          <div class="px-2 py-2">
            <div v-if="get(raffle, 'bonus_gateways.fanwork')" class="d-flex">
              <ion-badge class="raf-badge">+1</ion-badge>

              <div class="mx-2 flex-grow-1 mt-1">
                <div>
                  Every FanArt to
                  <router-link
                    :class="{
                      'text-color-username':
                        usernameColor(get(raffle, 'user')) && !usernameColor(get(raffle, 'user')).startsWith('#'),
                      'bold-username': usernameColor(get(raffle, 'user')),
                    }"
                    :style="
                      !usernameColor(get(raffle, 'user'))
                        ? ''
                        : usernameColor(get(raffle, 'user')).startsWith('#')
                        ? `color: ${usernameColor(get(raffle, 'user'))} !important`
                        : `background-image: ${usernameColor(get(raffle, 'user'))} !important`
                    "
                    :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }"
                    target="_blank"
                  >
                    @{{ get(raffle, 'user.username') }}
                  </router-link>
                  = Extra Entry! Submit More, Win More!
                  <i
                    class="ti-help-alt clickable-item-hov text-black"
                    style="font-size: 14px; margin-top: 10px"
                    @mouseover="openPopup"
                  />
                  <span v-if="get(bonusEntries, 'fanwork_bonus_earned') && !isRaffleOwner" class="text-primary mx-1"
                    >({{ get(bonusEntries, 'fanwork_bonus_earned') }} bonus
                    {{ get(bonusEntries, 'fanwork_bonus_earned') > 1 ? 'entries' : 'entry' }} earned)</span
                  >
                </div>
              </div>
            </div>
            <div v-if="get(raffle, 'bonus_gateways.social_media_sharing')" class="d-flex mt-2">
              <ion-badge class="raf-badge" :class="{ 'bg-primary': get(entryStatus, 'has_social_bonus') }">
                <div v-if="get(entryStatus, 'has_social_bonus')">
                  <i class="ti-check check-icon" />
                </div>

                <div v-else>+1</div></ion-badge
              >

              <div class="mx-2 flex-grow-1 mt-1">
                Share raffle on social media.
                <span
                  v-if="
                    isAuthenticated &&
                    !isRaffleOwner &&
                    get(entryStatus, 'has_entered') &&
                    !get(entryStatus, 'has_social_bonus') &&
                    raffle?.status === 'ongoing'
                  "
                  ><strong class="text-primary clickable-item-hov" @click="openSocialLinkModal">Click here</strong> to
                  submit proof.</span
                >
                <span
                  v-if="
                    get(entryStatus, 'has_social_bonus') && get(bonusEntries, 'social_bonus_earned') && !isRaffleOwner
                  "
                  class="text-primary"
                  >({{ get(bonusEntries, 'social_bonus_earned') }} bonus entry earned)</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="raffle-card px-3 py-3 mb-3">
          <div class="sub-title text-primary">Terms and conditions:</div>
          <div class="px-3 py-2">
            <span v-html="sanitizedTerms"></span>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div v-if="!isEmpty(raffle?.tags)" class="mb-3">
            <ion-badge
              v-for="tag in raffle?.tags"
              :key="tag"
              class="tags-clr ml-1 clickable-item-hov"
              @click="openTagSearch(tag)"
              >#{{ tag }}</ion-badge
            >
          </div>
          <div style="font-size: 20px" class="d-flex align-items-center">
            <strong>Offered by:</strong>
            <router-link
              :class="{
                'text-color-username':
                  usernameColor(get(raffle, 'user')) && !usernameColor(get(raffle, 'user')).startsWith('#'),
                'bold-username': usernameColor(get(raffle, 'user')),
              }"
              :style="
                !usernameColor(get(raffle, 'user'))
                  ? ''
                  : usernameColor(get(raffle, 'user')).startsWith('#')
                  ? `color: ${usernameColor(get(raffle, 'user'))} !important`
                  : `background-image: ${usernameColor(get(raffle, 'user'))} !important`
              "
              v-if="get(raffle, 'user.username')"
              :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }"
              class="mx-1"
            >
              @{{ get(raffle, 'user.username') }}
            </router-link>
            <ProfileBadge :badges="get(raffle, 'user.customize_profile.badges')" :show-small-badge="true" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="title">Wall of Thank You's!</div>
        <wallof-thank-yous-card :feedback-notes="notes" />
      </div>
    </div>
    <raffle-entry-modal
      v-if="raffle"
      :is-open="isRaffleEntryModalOpen"
      :raffle="raffle"
      :status="entryStatus"
      :entry="get(entryStatus, 'entry')"
      @thanks="openGiveawayFeedbackModal"
      @close="closeEntryModal"
      @link="openSocialLinkModal"
    />

    <social-media-link-proof-modal v-if="raffle" :is-open="isMediaLinkOpen" :raffle="raffle" @close="closeLinkModal" />
    <giveaway-feedback-modal
      v-if="raffle"
      :raffle="raffle"
      :is-open="isFeedbackModalOpen"
      @close="closeGiveAwayFeedbackModal"
    />
    <VisualPreviewModal
      :image-url="selectedVisualImg"
      :is-open="openVisualPreviewModal"
      :all-images="visuals"
      :is-featured="true"
      :active-index="activeIndex"
      :no-actions="true"
      :report-user="get(raffle, 'user')"
      @dismiss-modal="closeVisualPreviewModal"
      @updated="openVisualPreview"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import { arrowBackOutline, timeOutline, ribbonOutline, trophyOutline } from 'ionicons/icons';
import SocialMediaLinkProofModal from '@/shared/modals/SocialMediaLinkProofModal.vue';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import RaffleEntryModal from '@/shared/modals/RaffleEntryModal.vue';
import GiveawayFeedbackModal from '@/shared/modals/GiveawayFeedbackModal.vue';
import WallofThankYousCard from '@/shared/components/WallofThankYousCard.vue';
import { popovers } from '@/shared/native';
import SubmitFanartPopover from '@/shared/pages/raffles/popovers/submit-fanart-popover.vue';
import RafflePopover from '../popovers/RafflePopover.vue';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import {
  entryInGiveaway,
  getRaffleBonusEntries,
  getRaffleEntriesCount,
  getRaffleEntryStatus,
  getRaffleFeedBackNotes,
  getRaffleThankYouCounts,
  getRaffleWinnersAndRunnerUps,
} from '@/shared/actions/raffles';

import { sanitizeHtml } from '@/shared/utils/html';
import { raffleStore } from '@/shared/pinia-store/raffles';
import { authStore } from '@/shared/pinia-store/auth';
import { leadingZero } from '@/shared/utils/number';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';

const { user, isAuthenticated } = authStore();

const isMediaLinkOpen = ref(false);
const route = useRoute();
const { loadRaffleBySlug, raffle } = raffleStore();
const picture = ref('');
const title = ref('');
const description = ref('');
const visuals: any = ref([]);
const pageTitle = ref('');
const bonusEntries = ref({});
const router = useRouter();
const isRaffleEntryModalOpen = ref(false);
const isFeedbackModalOpen = ref(false);
const entryStatus = ref({});
const entriesCount: any = ref({});
const winners = ref([]);
const runnerUps = ref([]);
const selectedVisualImg = ref('');
const activeIndex: any = ref('');
const thankyouCounts: any = ref({});
const openVisualPreviewModal = ref(false);
const isThankYouWall = ref(false);
const notes: any = ref([]);
const url = ref(`https://characterhub.com${route.path}`);
const isEntryLoading = ref(true);

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const closeEntryModal = () => {
  isRaffleEntryModalOpen.value = false;
};
const openSocialLinkModal = () => {
  closeEntryModal();
  isMediaLinkOpen.value = true;
};

const dropdownToggle = (ev: CustomEvent) => {
  popovers.open(ev, RafflePopover, { raffle: raffle.value });
};

const closeLinkModal = () => {
  isMediaLinkOpen.value = false;
  fetchEntryStatus();
};

const closeGiveAwayFeedbackModal = () => {
  isFeedbackModalOpen.value = false;
  fetchEntryStatus();
};
const openGiveawayFeedbackModal = () => {
  isFeedbackModalOpen.value = true;
};

const openThankyouWall = () => {
  isThankYouWall.value = true;
};

const fetchEntryStatus = async () => {
  isEntryLoading.value = true;
  const resp = await getRaffleEntryStatus(raffle.value!.id as string);
  entryStatus.value = resp;
  isEntryLoading.value = false;
};

const openVisualPreview = (index: number) => {
  visuals.value = raffle?.value?.visuals?.map((item: any) => ({ visual_url: item }));
  selectedVisualImg.value = get(raffle.value, `visuals.${index}`, '');
  openVisualPreviewModal.value = true;
  activeIndex.value = index;
};

const openPopup = async (ev: CustomEvent) => {
  await popovers.open(ev, SubmitFanartPopover);
};

const fetchEntriesCount = async () => {
  const resp = await getRaffleEntriesCount(raffle.value!.id as string);
  entriesCount.value = resp;
};

const fetchFeedbackNotes = async () => {
  const resp = await getRaffleFeedBackNotes(raffle.value!.id as string);
  notes.value = resp;
};

const fetchThankYouCounts = async () => {
  const resp = await getRaffleThankYouCounts(raffle.value!.id as string);
  thankyouCounts.value = resp;
};
const fetchRaffleWinnersAndRunnerUps = async () => {
  const resp = await getRaffleWinnersAndRunnerUps(raffle.value!.id as string);
  winners.value = resp.filter((val: any) => val.type === 'winner');
  runnerUps.value = resp.filter((val: any) => val.type === 'runner_up');
};
watch(raffle, async () => {
  if (isRaffleOwner.value) {
    fetchEntriesCount();
    fetchFeedbackNotes();
    fetchThankYouCounts();
  } else {
    fetchEntryStatus();
    fetchBonusEntries();
  }

  if (get(raffle.value, 'status') === 'ended') {
    fetchRaffleWinnersAndRunnerUps();
  }
});

const countdownTime = computed(() => {
  if (!get(raffle.value, 'duration')) return 0;

  const durationInMilliseconds = get(raffle.value, 'duration', 0) * 24 * 60 * 60 * 1000;
  const startTime = new Date(get(raffle.value, 'start_date')).getTime();
  const endTime = startTime + durationInMilliseconds;
  const countdown = endTime - Date.now();
  return countdown > 0 ? countdown : 0;
});

const isRaffleOwner = computed(() => {
  try {
    return get(raffle.value, 'user.username') === user.value.username;
  } catch (error) {
    return false;
  }
});

const sanitizedDesc = computed(() => {
  try {
    return sanitizeHtml(get(raffle.value, 'description', '')).replace(/<a/g, '<a target="_blank"');
  } catch (error) {
    return false;
  }
});

const sanitizedTerms = computed(() => {
  try {
    return sanitizeHtml(get(raffle.value, 'terms_condition', '')).replace(
      /<a/g,
      '<a rel="nofollow" class="link" target="_blank"'
    );
  } catch (error) {
    return false;
  }
});

const fetchBonusEntries = async () => {
  const resp = await getRaffleBonusEntries(raffle.value?.id);
  bonusEntries.value = resp;
};
const closeVisualPreviewModal = () => {
  openVisualPreviewModal.value = false;
};
const openRaffEntryModal = async () => {
  if (!get(entryStatus.value, 'has_entered')) {
    const payload = {
      raffle_id: raffle.value?.id,
      type: 'entry',
    };
    await entryInGiveaway(payload);
    entryStatus.value = { ...entryStatus.value, has_entered: true };
  }
  isRaffleEntryModalOpen.value = true;
};

const goToEdit = () => {
  router.push({ name: 'edit-raffle', params: { id: raffle.value!.id } });
};

const openTagSearch = (tagText: any) => {
  setTimeout(() =>
    router.push({
      name: 'search',
      query: { term: `#${tagText}`, tab: 'all' },
    })
  );
};

await useChAsyncData(async () => {
  const { slug } = route.params;
  const slugAsString = slug as string;
  await loadRaffleBySlug(slugAsString);

  pageTitle.value = `${raffle.value?.title} - CharacterHub`;
  description.value = `Enter the ${raffle.value?.title} raffle on CharacterHub!`;
  title.value = `${get(raffle.value, 'title', '')} raffle`;
  picture.value = get(raffle.value, 'visuals[0]', '');

  useChHead(pageTitle, title, description, url, picture);
});
</script>

<style lang="sass" scoped>
.header-popover-button
  position: absolute
  right: 1.5rem
  width: 32px
  --border-radius: 16px
  --background: #989aa2
  height: 16px

  i
    font-size: 12px
    color: white
.raffle-card
  background: white
  border-radius: 10px

.dark .raffle-card
  background: #17074C
.check-icon
  display: flex
  justify-content: center
  align-items: center

.raf-badge
  background: #00b4c5
  font-weight: bold
  display: flex
  height: 28px
  width: 28px
  align-items: center
  justify-content: center
  min-width: 28px
  max-width: 28px

.bg-primary
  background: var(--ion-color-primary) !important

.badge
  width: 68px
  display: flex
  font-size: 14px
  align-items: center
  height: 18px
  justify-content: center
.icon
  margin-top: 2px

.swiper-container
  overflow: hidden
  min-height: 300px
// .swiper-base
//   padding-bottom: 40px !important
.slide
  // max-width: 770px
  // max-height: 216px
  width: 100%
  max-height: 300px
  min-height: 100px
  object-fit: cover
  // overflow: hidden
  // img
  border-radius: 20px !important

.slide-bg
  background-size: cover
  opacity: 0.3
  height: 100%

.slide-img
  object-fit: contain
  z-index: 99

.hide-cursor
  pointer-events: none
.raf-border
  border: 2px solid black
  border-radius: 10px
.dark .raf-border
  border: 2px solid white !important
.dark .text-dark
  color: black !important

.tags-clr
  color: black
  background: white

.dark .tags-clr
  color: black !important

.back-btn
  text-transform: none
  --border-radius: 15px
  --background: #214163
.enter-btn
  --background: var(--ion-color-primary)
  width: 135px
  --border-radius: 20px
.page
  position: relative
  top: -20px
.title
  font-size: 24px
  font-weight: bold
.sub-title
  font-size: 20px
  font-weight: bold
.sub-co-title
  font-size: 18px
  font-weight: bold
</style>
