<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true" @didDismiss="close">
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between mb-2">
        <div></div>
        <div>
          <div class="title text-black" style="text-align: center">Share on social media and paste the link here!</div>
        </div>
        <div>
          <ion-buttons>
            <ion-button class="close-button text-black" @click.prevent="close">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="gap: 15px">
        <div class="d-flex flex-column align-items-center">
          <ion-button class="icon-btn" @click.prevent="copyUrl">
            <i class="ti-link icon-font" />
          </ion-button>
          <small>Copy Link</small>
        </div>
        <div class="d-flex flex-column align-items-center">
          <a :href="`https://www.facebook.com/sharer/sharer.php?u=${encodedFullPath}`" target="_blank" rel="noopener">
            <ion-button class="icon-btn">
              <i class="ti-facebook icon-font" />
            </ion-button>
          </a>
          <small>Facebook</small>
        </div>
        <div class="d-flex flex-column align-items-center">
          <a
            :href="`http://twitter.com/share?text=${encodedText}&url=${encodedFullPath}&hashtags=characterhub,raffles`"
            target="_blank"
            rel="noopener"
          >
            <ion-button class="icon-btn">
              <i class="ti-twitter-alt icon-font" />
            </ion-button>
          </a>
          <small>Twitter</small>
        </div>
        <div class="d-flex flex-column align-items-center">
          <a :href="`http://www.tumblr.com/share/link?url=${encodedFullPath}`" target="_blank" rel="noopener">
            <ion-button class="icon-btn">
              <i class="ti-tumblr-alt icon-font" />
            </ion-button>
          </a>
          <small>Tumblr</small>
        </div>
      </div>

      <ion-textarea
        v-model="mediaLink"
        rows="3"
        class="c-textarea mt-3"
        required
        placeholder="Post link to the post you made and get a bonus entry!*"
      ></ion-textarea>

      <div class="d-flex justify-content-center mt-2">
        <ion-button
          class="submit-btn"
          :disabled="isSubmitting || !isFormComplete"
          type="submit"
          @click.prevent="onSubmit"
        >
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>Submit</span>
        </ion-button>
      </div>
      <div class="d-flex mt-2">
        <small class="text-black"
          >*We do check to verify your post.Folks gaming the system will be <strong>barred from all raffles.</strong>
        </small>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Raffle } from '@/shared/types/static-types';
import { truncateText } from '@/shared/utils/string';
import { toast } from '@/shared/native';
import { submitRaffleSocialBonus } from '@/shared/actions/raffles';

const route = useRoute();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  raffle: {
    type: Object as PropType<Raffle>,
    required: true,
  },
});

const raffle = toRef(props, 'raffle');
const mediaLink = ref('');
const isSubmitting = ref(false);
const emits = defineEmits(['close', 'link']);

const isFormComplete = computed(() => {
  return !!mediaLink.value;
});

const raffleTitle = computed(() => {
  return get(raffle.value, 'title') as string;
});

const onSubmit = async () => {
  if (!isFormComplete) return;

  isSubmitting.value = true;
  try {
    await submitRaffleSocialBonus({ raffle_id: raffle.value.id, text: mediaLink.value });
    await toast.show(
      `You've earned an extra entry for "${truncateText(raffleTitle.value, 15)}"!`,
      'nonative',
      'success'
    );
  } finally {
    isSubmitting.value = false;
  }
  emits('close');
};

const close = () => {
  emits('close');
};

const copyUrl = async () => {
  try {
    const {
      public: { hostUrl },
    } = useRuntimeConfig();
    await navigator.clipboard.writeText(`${hostUrl}${route.fullPath}`);
    await toast.show('Link copied!', 'nonative', 'success');
  } catch ($e) {
    await toast.show('Unable to copy. Please try again.', 'nonative', 'danger');
  }
};

const encodedFullPath = computed(() => {
  return encodeURIComponent(`${window.location.origin}${route.fullPath}`);
});

const encodedText = encodeURIComponent('Checkout this raffle!');
</script>

<style lang="sass" scoped>
.icon-font
  font-size: 18px
.icon-btn
  --border-radius: 20px
  width: 40px
  height: 40px
.title
  font-weight: bold
  font-size: 22px
.submit-btn
  width: 150px
  text-transform: none
  --border-radius: 20px

ion-modal
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
</style>
