<template>
  <div v-if="feedbackNotes && feedbackNotes.length">
    <ion-card
      v-for="(note, index) of feedbackNotes"
      :key="index"
      class="thanks-card-small d-flex py-3 px-3 mx-0 text-black"
    >
      {{ note.thanks_string }} -
      <router-link
        target="_blank"
        :to="{ name: 'profile', params: { username: get(note, 'user.username') } }"
        class="ml-1"
      >
        @{{ get(note, 'user.username') }}
      </router-link>
    </ion-card>
  </div>
  <div v-else class="no-data">No appreciations yet</div>
</template>

<script lang="ts" setup>
const props = defineProps({
  feedbackNotes: {
    type: Array,
    default: [],
  },
});
const feedbackNotes = toRef(props, 'feedbackNotes');
</script>

<style></style>
