<template>
  <div>
    <report
      :is-popover="true"
      :report-entity-id="raffle?.id"
      :report-entity-type="'raffle'"
      :reported-user="raffle.user"
      @close="dismiss"
    >
      <ion-item class="item-container d-flex pointer no-select">
        <div class="menu-item d-flex align-items-center no-select text-danger">
          <i class="ti-alert mr-1" /><span>Report </span>
        </div>
      </ion-item>
    </report>
  </div>
</template>

<script lang="ts" setup>
import Report from '@/shared/components/Report.vue';
import { OverlayInterface } from '@ionic/core';

const props = defineProps({
  instance: {
    type: Object as PropType<OverlayInterface>,
  },
  raffle: {
    type: Object,
    default: {},
  },
});

const instance = toRef(props, 'instance') as any;

const dismiss = async (value: any) => {
  await instance.value.dismiss();
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
