import { defineStore, storeToRefs } from 'pinia';
import { authStore } from './auth';
import { toast } from '@/shared/native/toast';
import { Raffle } from '@/shared/types/static-types';

import { getRaffleFromSlug } from '@/shared/actions/raffles';

export interface IRaffleState {
  currentRaffle: Raffle | null;
  isLoading: boolean;
}

const raffleAndGiveaway = defineStore('raffle-giveaway', {
  state: (): IRaffleState => ({
    currentRaffle: null,
    isLoading: false,
  }),
  actions: {
    reset() {
      this.currentRaffle = null;
    },

    async loadRaffleBySlug(slug: string) {
      try {
        this.isLoading = true;
        const { user } = authStore();
        const raffle = await getRaffleFromSlug(slug);
        this.currentRaffle = raffle;
      } catch (error: any) {
        toast.show('Error loading raffle', 'nonative', 'danger');
        this.isLoading = false;
      }
    },
  },
  getters: {
    raffle(): Raffle | null {
      return this.currentRaffle;
    },
    loading(): boolean {
      return this.isLoading;
    },
  },
});

export const raffleStore = () => {
  const store = raffleAndGiveaway();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
