<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="false"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <div class="inner-content d-flex flex-column py-3">
      <div class="d-flex justify-content-between mt-2 px-3">
        <div>
          <div class="title text-black" style="text-align: center">
            Thank
            <router-link
              :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }"
              target="_blank"
              class="clickable-item-hov"
              >@{{ get(raffle, 'user.username') }}!</router-link
            >
            Click any of the thank you messages below to say thanks!
          </div>
        </div>
        <div>
          <ion-buttons>
            <ion-button class="close-button text-black" @click.prevent="close">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <div class="reaction-content px-3 mt-2">
        <div class="d-flex flex-column">
          <div v-for="(reaction, index) in fanwork_reactions" :key="index">
            <ion-item button class="mb-2" style="--border-radius: 30px" @click.stop="handleCheckboxClick(reaction)">
              <ion-checkbox
                slot="start"
                :checked="selectedReaction === reaction"
                style="margin-inline-end: 5px; visibility: hidden"
              ></ion-checkbox>
              <i class="ti-comment-alt" :class="selectedReaction === reaction ? 'icon-checked' : 'icon-unchecked'" />
              <ion-label
                class="mx-2 label-name text-black"
                :class="selectedReaction === reaction ? 'icon-checked' : 'icon-unchecked'"
                text-wrap
                >{{ reaction.name }}</ion-label
              >
            </ion-item>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <ion-button class="submit-btn" type="submit" :disabled="!isFormComplete" @click.prevent="onSubmit">
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>Submit</span>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Raffle } from '@/shared/types/static-types';

import constants from '@/shared/statics/constants';
import { postThankyouRaffleNote } from '@/shared/actions/raffles';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  raffle: {
    type: Object as PropType<Raffle>,
    required: true,
  },
});

const raffle = toRef(props, 'raffle');
const isSubmitting = ref(false);
const emits = defineEmits(['close', 'link']);
const fanwork_reactions: any = ref([]);
const isOpen = toRef(props, 'isOpen');

const feedback_reactions: any = ref([]);
const selectedReaction: any = ref(null);

watch(
  () => props.isOpen,
  async () => {
    if (isOpen.value) {
      fanwork_reactions.value = sampleSize(constants.giveawayReactions, 10).map((item: string) => ({
        checked: false,
        name: item,
      }));
    }
  }
);

const close = () => {
  emits('close');
};

const handleCheckboxClick = (reaction: any) => {
  if (selectedReaction.value === reaction) {
    selectedReaction.value = null;
  } else {
    selectedReaction.value = reaction;
  }
};

const isFormComplete = computed(() => {
  return !isEmpty(selectedReaction.value);
});

const onSubmit = async () => {
  if (selectedReaction.value) {
    feedback_reactions.value = selectedReaction.value.name;
  }
  const payload = {
    raffle_id: raffle.value.id,
    thanks_string: feedback_reactions.value,
  };
  await postThankyouRaffleNote(payload);
  close();
};
</script>

<style lang="sass" scoped>
.icon-checked
  color: var(--ion-color-primary) !important
.icon-unchecked
  color: black
.title
  font-weight: bold
  font-size: 24px

.reaction-content
  max-height: 70vh
  overflow-x: hidden
  overflow-y: auto

.label-name
  padding-right: 20px

.modal-big
  --width: 750px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 90vh
  overflow: auto

ion-item::part(native)
  color: #fff
  border-radius: 10px
  padding-inline-start: 0px
  padding-inline-end: 0px
  padding-left: 0.5rem !important
  padding-right: 0.5rem !important

ion-item::part(detail-icon)
  color: white
  opacity: 1
  font-size: 20px

ion-checkbox
  --border-radius: 6px
  --background: var(--ion-color-primary)
  --border-color: #fff
  --background-checked: #fff
  --border-color-checked: #fff
  --checkmark-color: white
</style>
